const initialCountdownValue = 60;

interface CountdownOptions {
  startWith?: number;
  countFrom?: number;
  increasingDelays?: boolean;
}

export const useCountdown = (options?: CountdownOptions) => {
  const startWith = options?.startWith ?? initialCountdownValue;
  const countFrom = ref(options?.countFrom ?? initialCountdownValue);
  const finishedOnStart = startWith === 0;

  const skipDoubleOnFirstRestart = ref(finishedOnStart);
  const countdownCounter = ref(startWith);
  const countdownOver = computed(() => countdownCounter.value === 0);

  let countdownIntervalId: NodeJS.Timeout | undefined;

  onMounted(() => {
    countdownIntervalId = setInterval(() => {
      countdownCounter.value = Math.max(countdownCounter.value - 1, 0);
    }, 1000);
  });

  onUnmounted(() => {
    if (countdownIntervalId) {
      clearInterval(countdownIntervalId);
    }
  });

  const restartCountdown = () => {
    if (skipDoubleOnFirstRestart.value) {
      skipDoubleOnFirstRestart.value = false;
    } else if (options?.increasingDelays) {
      countFrom.value *= 2;
    }
    countdownCounter.value = countFrom.value;
  };

  return {
    countdownCounter,
    countdownOver,
    restartCountdown,
  };
};
